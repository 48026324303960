"use client";
import React from "react";
import { Link } from "react-router-dom";
const page = () => {
  return (
    <>
      <section className="position-relative">
        <div className="container">
          <div className="web-container">
            <div className="row">
              <div className="col-md-6 pt-5 col-12 mx-auto">
                <div className="sucees_mesg-message mx-auto">
                  <p className="text-center">
                    Thank you for contacting us! Your enquiry has been
                    successfully submitted. Our team will review your message
                    and get back to you shortly. If your matter requires urgent
                    attention, please call us at{" "}
                    <Link to="/tel:0181-4667555">0181-4667555.</Link>{" "}
                  </p>
                  <p className="text-center">
                    Thank you again for choosing Assure Pathlabs.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default page;
