import * as React from "react";
const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-5.0 -10.0 110.0 135.0"
    {...props}
  >
    <path d="m74.043 29.973-2.4883 8.7695h-46.555l2.4883-8.7695zm-22.656 55.254-25.426-30.859-0.070313-6.9258h12.605c3.1484 0 5.8125-0.48438 7.9883-1.4531 2.1797-0.97266 3.8359-2.3789 4.9727-4.2266 1.1367-1.8711 1.7031-4.1445 1.7031-6.8203 0-3.9766-1.1836-7.1367-3.5508-9.4805s-6.0703-3.5156-11.113-3.5156h-13.496l2.5938-9.4453h10.902c5.8711 0 10.711 0.93359 14.523 2.8047 3.8125 1.8711 6.6523 4.4648 8.5234 7.7773 1.8711 3.3164 2.8047 7.1367 2.8047 11.469 0 3.8594-0.76953 7.3516-2.3086 10.477-1.5391 3.125-3.9883 5.6719-7.3516 7.6367-3.3594 1.9648-7.7773 3.1133-13.246 3.4453l-0.31641 0.070313 23.223 28.445v0.60156zm22.691-72.727-2.5234 8.8438-37.215-0.21484 2.5234-8.6289z" />
  </svg>
);
export default SVGComponent;
