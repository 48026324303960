import * as React from "react";
const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 90 112.5"
    x="0px"
    y="0px"
    {...props}
  >
    <defs>
      <style>{".cls-1{fill-rule:evenodd;}"}</style>
    </defs>
    <path
      className="cls-1"
      d="M1.174,32.074V71.4a3.857,3.857,0,0,0,3.852,3.852h15.1A3.855,3.855,0,0,0,23.973,71.4V32.074a3.856,3.856,0,0,0-3.852-3.852H5.026a3.857,3.857,0,0,0-3.852,3.852ZM14.261,64.728a1.511,1.511,0,1,1,0,3.022H8.9a1.511,1.511,0,1,1,0-3.022ZM27,32.74V67.688c.922,2.742,3.614,5.019,8.01,6.772l15.02-.041A21.373,21.373,0,0,1,78.916,48.558a30.9,30.9,0,0,0-1.68-14.06,8.115,8.115,0,0,0-7.664-5.183H54.188a1.512,1.512,0,0,1-1.453-1.932c1.712-5.9,2.262-10.839,1.636-14.677a11.208,11.208,0,0,0-4.915-8.058C46.9,3.456,44.9,3.156,43.5,3.754a4.8,4.8,0,0,0-2.428,3.109c-.865,9.4-5.6,18.1-14.081,25.877Zm43.479,17.1A18.352,18.352,0,1,1,52.122,68.193,18.373,18.373,0,0,1,70.474,49.841ZM67.291,67.91l8.967-7.989a3.794,3.794,0,0,1,4.962-.173l.025.02a3.493,3.493,0,0,1,.251,5.157l-11.37,11.45a3.767,3.767,0,0,1-5.255.066l-5.295-5.073a3.7,3.7,0,0,1-.057-5.287l.015-.016a3.764,3.764,0,0,1,4.947-.185l2.81,2.03Zm1.1,3.065,9.876-8.8a.779.779,0,0,1,1.025-.1l.023.019a.409.409,0,0,1,.17.341.414.414,0,0,1-.136.357L67.982,74.246a.731.731,0,0,1-1.02.012l-5.3-5.073a.674.674,0,0,1-.011-.967l.015-.016a.476.476,0,0,1,.345-.135,1.4,1.4,0,0,1,.694.263L66.5,71.071A1.513,1.513,0,0,0,68.394,70.975Z"
    />
  </svg>
);
export default SVGComponent;
