import React, { useEffect, useState } from "react";
import axios from "axios"; // You can also use the fetch API
import { Link } from "react-router-dom";
import "./index.css";
import { BASE_URL } from "./context/UseApi";

const DoctorListing = () => {
  const [doctors, setDoctors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch data from the API
    axios
      .get(`${BASE_URL}/doctor_data.php`)
      .then((response) => {
        // Assuming the API returns data in the format { doctors: [...] }
        setDoctors(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching the doctor data:", error);
        setError("Failed to load doctor data.");
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className="_loader_cnt col-12 d-flex justify-content-center">
        <div className="_loader"></div>
      </div>
    );
  }

  if (error) {
    return <div className="h-screen col-12 flex-center ">{error}</div>;
  }

  return (
    <>
      <section className="position-relative">
        <div className="container">
          <div className="row">
            <div className="title col-12 float-start text-center">
              <h2>Our Doctors</h2>
            </div>
            <div className="leaderships">
              {doctors.map((doctor, index) => (
                <div className="leadership2" key={index}>
                  <div className="leadershipimg">
                    <img
                      src={doctor.image.src}
                      alt={doctor.image.alt}
                      title={doctor.image.title}
                      width="350"
                      height="350"
                    />
                  </div>
                  <div className="leadershipcont">
                    <h2>{doctor.doctor_name}</h2>
                    <ul>
                      <li>{doctor.position}</li>
                      <li>{doctor.organization}</li>
                    </ul>
                    <div className="col-12 d-flex justify-content-start">
                      <Link
                        to={`/doctor/${doctor.slug}`}
                        className="textbtn text-black mt-2"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DoctorListing;
